<template>
    <q-banner v-bind="bannerProps">
        <q-item dense class="q-pl-none">
            <q-item-section side v-if="!hideIcon">
                <q-icon v-bind="icon"/>
            </q-item-section>
            <q-item-section>
                <q-item-label>
                    <slot v-if="htmlMessage" name="default"><span v-html="htmlMessage"/></slot>
                    <slot v-else name="default">{{ message }}</slot>
                </q-item-label>
            </q-item-section>
        </q-item>
    </q-banner>
</template>
<script setup lang="ts">
import {computed} from "vue";
interface Props {
    variant: 'success' | 'warning' | 'danger' | 'info',
    message?: string,
    htmlMessage?: string,
    icon?: string,
    hideIcon?: boolean,
    dense?: boolean
}

const props = withDefaults(defineProps<Props>(), {hideIcon: false, dense: false})

const bannerProps = computed(() => {
    return {
        class: ['q-my-xs', 'bg-' + bgColor.value, 'text-' + textColor.value],
        dense: props.dense
    }
})
const bgColor = computed(() => {
    switch (props.variant) {
        case"info":
            return "light-blue-2";
        case"danger":
            return "red-6";
        case"success":
            return "positive";
        case"warning":
            return "warning";
    }
})
const textColor = computed(() => {
    switch (props.variant) {
        case"info":
            return "dark";
        case"danger":
            return "white";
        case"success":
            return "white";
        case"warning":
            return "white";
    }
})
const icon = computed(() => {
    let opt = {
        size: 'md',
        name: 'o_info',
        color: 'white'
    }
    switch (props.variant) {
        case"info":
            opt.name = 'o_info';
            opt.color = 'primary';
            break;
        case"danger":
            opt.name = 'o_report_problem';
            break;
        case"success":
            opt.name = 'o_check_circle';
            break;
        case"warning":
            opt.name = 'o_warning_amber';
            break;
    }
    return opt
})
</script>