<template>
    <q-btn icon="apps"
           @click="serviceSelectorSide = !serviceSelectorSide"
           aria-label="Menu"
           flat dense square
           size="xl">
        <q-tooltip class="text-body2" anchor="center end" self="center left">
            {{ $translate('selector.service') }}
        </q-tooltip>
    </q-btn>
    <q-drawer v-model="serviceSelectorSide"
              class="bg-white"
              :width="300"
              elevated overlay>
        <q-scroll-area class="fit">
            <q-list separator>
                <template v-for="(menuItem, index) in services" :key="index">
                    <q-item :active="index === currentService"
                            v-if="!menuItem.disabled"
                            @click="gotoService(menuItem.url)"
                            clickable v-ripple
                    >
                        <q-item-section class="text-weight-bold">
                            {{ menuItem.name }}
                        </q-item-section>
                        <q-item-section v-if="menuItem.description">
                            {{ menuItem.description }}
                        </q-item-section>
                    </q-item>
                </template>
            </q-list>
        </q-scroll-area>
    </q-drawer>
</template>

<script setup>
import {computed, getCurrentInstance, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";

const app = getCurrentInstance();
const $route = app.appContext.config.globalProperties.$route;
const $translate = app.appContext.config.globalProperties.$translate;

const serviceSelectorSide = ref(false);

const currentService = computed(() => usePage().props.service);
const services = computed(() => usePage().props.services);

const gotoService = (service) => {
    router.visit(service)
    serviceSelectorSide.value = false
}
</script>