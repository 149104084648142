<template>
    <q-bar class="bg-grey-1 q-py-sm q-mb-sm">
        <h6 class="q-my-none">{{ $translate('cadre-staff.label.vacation') }}</h6>
        <q-space/>
        <q-btn v-if="_.get(can,'vacations.create')"
               icon="add"
               :label="$translate('buttons.add')"
               color="white" text-color="black"
               size="md" dense no-caps
               @click="openAddDialog"
        />
        <q-separator vertical class="q-mx-md"/>
        <q-select v-model="currentYear"
                  @update:modelValue="loadItems"
                  :options="optionsYears"
                  :prefix="$translate('на')"
                  :suffix="$translate('units.year')"
                  options-dense
                  dense/>
        <q-btn-toggle v-model="tab"
                      spread no-caps
                      toggle-color="blue-grey-3" color="white" text-color="black"
                      :options="[{value: 'table', slot:'table'},{value: 'chart',slot:'chart'}]"
                      @update:model-value="loadItems"
        >
            <template v-slot:table>
                <q-icon name="sym_o_table" size="sm"/>
                <q-tooltip class="text-body2">{{ $translate('buttons.view-as-table') }}</q-tooltip>
            </template>
            <template v-slot:chart>
                <q-icon name="sym_o_grouped_bar_chart" size="sm"/>
                <q-tooltip class="text-body2">{{ $translate('buttons.view-as-chart') }}</q-tooltip>
            </template>
        </q-btn-toggle>
    </q-bar>

    <template v-if="tab==='table'">
        <q-table :rows="items"
                 :columns="tableColumns"
                 :pagination="{rowsPerPage: 0}"
                 :rows-per-page-options="[0]"
                 :loading="loading"
                 hide-pagination
                 bordered
                 flat
        >
            <template v-slot:body-cell-type="props">
                <q-td :props="props">
                    <div>{{ props.value }}</div>
                    <div class="text-smaller">{{ props.row.comment }}</div>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <VacationActions :vacation="props.row" @onSuccess="loadItems"/>
                </q-td>
            </template>
            <template v-slot:bottom-row>
                <q-tr>
                    <q-th colspan="100%" align="left">Сводная информация</q-th>
                </q-tr>
                <template v-for="(d,c) in summaryItems">
                    <q-tr>
                        <q-td></q-td>
                        <q-td colspan="1">{{ c }}</q-td>
                        <q-td colspan="100%">{{ d }} {{ $translate('units.days')}}</q-td>
                    </q-tr>
                </template>
            </template>
        </q-table>
    </template>
    <template v-if="tab==='chart'">
        <Chart v-if="!_.isEmpty(chartData)"
               chart-type="gantt-date"
               :data="chartData"
               :config="chartConfig"
               :height="chartHeight"
        />
    </template>
    <q-dialog v-model="addDialog" no-backdrop-dismiss>
        <Card :title="$translate('cadre-vacation.title.create')"
              in-modal show-close-button header-toolbar>
            <ProfileVacationForm v-model="addForm"
                                 :is-staff="false"
                                 :position="_.filter([_.get(staff,'name'), _.get(staff,'cadre_position.name'),_.get(staff,'cadre_department.name')]).join(', ')"/>
            <template v-slot:actions>
                <q-btn icon="o_save"
                       :label="$translate('buttons.save')"
                       color="positive"
                       @click="addFormSubmit"
                />
            </template>
        </Card>
    </q-dialog>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, Ref, ref, computed, ComputedRef} from "vue";
import {usePage, useForm} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {CadreVacation, CadreStaff} from "@/entity"
import Chart from "@/Components/Chart.vue";
import VacationActions from "@/Components/Cadre/Vacation/VacationActions.vue";
import {AnyValuesObject, StringValuesObject} from "@/entity/types";
import {IGanttDateChartConfig} from "@/entity/charts";
import {toValue} from "@vueuse/core";
import ProfileVacationForm from "@/Components/Cadre/Vacation/ProfileVacationForm.vue";
import {Card} from "@/Components/Block";


const app = getCurrentInstance();
const $translate = app!.appContext.config.globalProperties.$translate;
const $route = app!.appContext.config.globalProperties.$route;

interface Props {
    staff: CadreStaff
}

const props = defineProps<Props>()

const tab = ref('table')

const loading = ref(false)

const optionsYears: any = computed(() => usePage().props.vacationYears)

const can: any = computed(() => usePage().props.can)

const currentYear = ref(moment().year())

const items = ref([])
const summaryItems = ref({})

const tableColumns: any = [
    {name: 'actions', label: '', style: 'width:20px'},
    {
        name: 'type',
        field: 'type_text',
        label: $translate('cadre-vacation.fields.type'),
        align: 'left'
    },
    {
        name: 'date_start',
        field: 'date_start',
        label: $translate('cadre-vacation.fields.date_start'),
        style: 'width:200px',
        align: 'center'
    },
    {
        name: 'date_end',
        field: 'date_end',
        label: $translate('cadre-vacation.fields.date_end'),
        style: 'width:200px',
        align: 'center'
    },
    {
        name: 'days',
        field: 'vacation_days',
        label: $translate('cadre-vacation.fields.days'),
        style: 'width:100px',
        align: 'center'
    },
    {
        name: 'status',
        field: 'status_text',
        label: $translate('cadre-vacation.fields.status'),
        align: 'left'
    },
]
const loadItems = () => {
    loading.value = true;
    axios.get($route('ajax.cadre.staff', {m: 'vacations', staff_id: props.staff.id, year: currentYear.value}))
            .then((response) => {
                items.value = response.data.results
            })
            .then(() => {
                summaryItems.value = _.mapValues(_.groupBy(items.value, 'type_text'), (it, cat) => (_.sumBy(it, 'vacation_days')));
            })
            .finally(() => {
                if ("chart" === tab.value) {
                    refillChartData();
                }
                loading.value = false
            })
}


const addDialog = ref(false)

const openAddDialog = () => {
    addDialog.value = true;
}

const addForm = useForm({
    action: null,
    vacation: {
        cadre_staff_id: props.staff.id,
        type: null,
        date_start: null,
        date_end: null,
        vacation_days: 0,
    },
    comment: null
})

const addFormSubmit = () => {
    addForm
            .transform((data) => ({
                        ...data.vacation,
                        comment: data.comment,

                    })
            )
            .post($route('cadre.vacation.add'), {
                onSuccess: () => {
                    addForm.reset()
                    addDialog.value = false;
                    loadItems()
                }
            });
}

/*******************************************
 * настройки для графика
 ***************************************/

const chartData: Ref<AnyValuesObject[] | never[]> = ref<AnyValuesObject[] | never[]>([]);
const chartConfig: ComputedRef<IGanttDateChartConfig> = computed<IGanttDateChartConfig>(() => ({
    chartSettings: {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
    },
    chartWidgets: {
        cursor: {behavior: "zoomX"},
    },
    categoryYAxis: {
        categoryField: "type_text",
        categories: [],
        rendererSettings: {
            minGridDistance: 30
        },
        rendererGridTemplateLocation: 1
    },
    dateXAxis: {
        baseInterval: {timeUnit: "day", count: 1},
        gridIntervals: [
            {timeUnit: "day", count: 1},
            {timeUnit: "week", count: 1},
            {timeUnit: "month", count: 1},
        ],
        additionalSettings: {
            min: moment(currentYear.value + "-01-01").toDate().getTime(),
            max: moment(currentYear.value + "-12-31").toDate().getTime(),
            strictMinMax: true,
            tooltipDateFormat: "dd.MM.yyyy"
        },
        rendererSettings: {
            strokeOpacity: 0.1
        }
    },
    columnSeries: {
        openValueXField: "date_start",
        valueXField: "date_end",
        categoryYField: "type_text",
        tooltipText: "{type_text} ({status_text}):\n[bold]{openValueX.formatDate('dd.MM.yyyy')}[/] - [bold]{valueX.formatDate('dd.MM.yyyy')}[/] ({vacation_days} дней)\n{comment}",
        dataFieldToProcess: "vacations",
        dateFields: ["date_start", "date_end"],
        dateFormat: "yyyy-MM-dd",
    },
}));
const chartHeight: Ref<string> = ref<string>("500px");

const refillChartData: Function = () => {
    chartData.value = [];
    chartConfig.value.categoryYAxis.categories = [];
    let categories: StringValuesObject[] = [], data: AnyValuesObject[] = [];
    _.forEach(toValue(items), (item: CadreVacation) => {
        categories.push({
            [chartConfig.value.categoryYAxis.categoryField]: item[chartConfig.value.categoryYAxis.categoryField]
        });
    });
    categories = _.uniqBy(categories, chartConfig.value.categoryYAxis.categoryField);
    if (1 === _.size(categories)) {
        chartHeight.value = "250px";
    }
    _.forEach(categories, (category: StringValuesObject) => {
        data.push({
            [chartConfig.value.categoryYAxis.categoryField]: category[chartConfig.value.categoryYAxis.categoryField],
            [chartConfig.value.columnSeries.dataFieldToProcess]: _.filter(
                    toValue(items),
                    (item: CadreVacation) => item[chartConfig.value.categoryYAxis.categoryField] === category[chartConfig.value.categoryYAxis.categoryField]
            )
        });
    });
    chartData.value = data;
    chartConfig.value.categoryYAxis.categories = _.reverse(
            _.sortBy(categories, chartConfig.value.categoryYAxis.categoryField)
    );
};

onMounted(() => {
    loadItems()
})
</script>