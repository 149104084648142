<template>
    <Card :title="$translate('users.manage.main-data.title')"
          header-toolbar hide-actions>
        <div :class="{'row q-gutter-x-md':$q.screen.gt.sm,'column q-gutter-y-md':$q.screen.lt.sm}">
            <div class="col q-gutter-y-md">
                <section>
                    <q-bar class="bg-grey-1">{{ $translate('users.manage.main-data.title-primary') }}</q-bar>
                    <div class="q-mx-md">
                        <q-field :label="$translate('users.fields.first_name')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.first_name }}
                                </div>
                            </template>
                            <template v-slot:append
                                      v-if="user.ext_login">
                                <q-icon name="o_lock" size="xs"/>
                                <q-tooltip class="text-body2">{{ $translate('users.profile.hasextlogin') }}</q-tooltip>
                            </template>
                            <template v-slot:append
                                      v-if="!user.ext_login">
                                <q-btn icon="o_edit" flat dense>
                                    <q-popup-edit v-model="form.first_name"
                                                  ref="first_name"
                                                  v-slot="scope"
                                                  @update:modelValue="(v) => patchData('first_name',v)"
                                                  buttons
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  :title="$translate('users.fields.first_name')">
                                        <q-input
                                                v-model="scope.value"
                                                input-class="text-center"
                                        />
                                    </q-popup-edit>
                                </q-btn>
                            </template>
                        </q-field>
                        <q-field :label="$translate('users.fields.middle_name')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.middle_name }}
                                </div>
                            </template>
                            <template v-slot:append
                                      v-if="user.ext_login">
                                <q-icon name="o_lock" size="xs"/>
                                <q-tooltip class="text-body2">{{ $translate('users.profile.hasextlogin') }}</q-tooltip>
                            </template>
                            <template v-slot:append
                                      v-if="!user.ext_login">
                                <q-btn icon="o_edit" flat dense>
                                    <q-popup-edit v-model="form.middle_name"
                                                  ref="middle_name"
                                                  v-slot="scope"
                                                  @update:modelValue="(v) => patchData('middle_name',v)"
                                                  buttons
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  :title="$translate('users.fields.middle_name')">
                                        <q-input
                                                v-model="scope.value"
                                                input-class="text-center"
                                        />
                                    </q-popup-edit>
                                </q-btn>
                            </template>
                        </q-field>
                        <q-field :label="$translate('users.fields.last_name')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.last_name }}
                                </div>
                            </template>
                            <template v-slot:append
                                      v-if="user.ext_login">
                                <q-icon name="o_lock" size="xs"/>
                                <q-tooltip class="text-body2">{{ $translate('users.profile.hasextlogin') }}</q-tooltip>
                            </template>
                            <template v-slot:append
                                      v-if="!user.ext_login">
                                <q-btn icon="o_edit" flat dense>
                                    <q-popup-edit v-model="form.last_name"
                                                  ref="last_name"
                                                  v-slot="scope"
                                                  @update:modelValue="(v) => patchData('last_name',v)"
                                                  buttons
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  :title="$translate('users.fields.last_name')">
                                        <q-input
                                                v-model="scope.value"
                                                input-class="text-center"
                                        />
                                    </q-popup-edit>
                                </q-btn>
                            </template>
                        </q-field>
                        <q-field :label="$translate('users.fields.birthdate')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.birthdate }}
                                </div>
                            </template>
                            <template v-slot:append>
                                <q-btn icon="o_edit" flat dense>
                                    <q-popup-edit v-model="form.birthdate"
                                                  ref="birthdate"
                                                  v-slot="scope"
                                                  @update:modelValue="(v) => patchData('birthdate',v)"
                                                  buttons
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  :title="$translate('users.fields.birthdate')">
                                        <q-input type="date"
                                                 v-model="scope.value"
                                                 input-class="text-center"
                                        />
                                    </q-popup-edit>
                                </q-btn>
                            </template>
                        </q-field>
                        <q-field :label="$translate('users.fields.ext_login')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.ext_login }}
                                </div>
                            </template>
                            <template v-slot:append>
                                <q-btn icon="sync_lock"
                                       v-if="user.ext_login"
                                       dense no-caps flat
                                       @click="openSyncDataDialog">
                                    <q-tooltip class="text-body2">{{ $translate('buttons.syncexternal') }}</q-tooltip>
                                </q-btn>
                                <q-btn icon="link"
                                       v-else
                                       dense no-caps flat
                                       @click="openAssignDataDialog">
                                    <q-tooltip class="text-body2">{{
                                            $translate('buttons.assign-external')
                                        }}
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-field>
                        <q-field :label="$translate('users.fields.email')" stack-label tag="div">
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    {{ user.email }}
                                </div>
                            </template>
                        </q-field>
                        <div :class="{'text-left':$q.screen.gt.sm,'text-right':$q.screen.lt.sm,'q-gutter-x-md':true}">
                            <q-btn icon="o_insert_photo"
                                   :label="$translate('buttons.changeavatar')"
                                   class="q-mt-sm"
                                   dense no-caps
                                   @click="openAvatarDialog"/>
                        </div>
                        <Card :title="$translate('buttons.changeavatar')"
                              v-if="avatarDialog"
                              class="q-mt-md">
                            <q-file v-model="avatarForm.avatar"
                                    accept=".jpeg,.png,.jpg"
                                    max-file-size="2048000"
                                    :hint="$translate('Формат .jpeg,.png,.jpg, максимум 5Кб')"
                                    dense borderless
                                    :error="avatarForm.errors.hasOwnProperty('avatar')"
                                    :error-message="_.get(avatarForm.errors,'avatar')">
                                <template v-slot:prepend>
                                    <q-icon name="o_attach_file"/>
                                </template>
                            </q-file>
                            <template v-slot:actions>
                                <q-btn icon="upload"
                                       :label="$translate('buttons.upload')"
                                       @click="submitAvatarForm"
                                       dense color="positive"/>
                            </template>
                        </Card>
                    </div>
                </section>
                <section>
                    <ProfileLangSelector :user="user" :locale-list="localeList"/>
                </section>
                <section v-if="!user.ext_login">
                    <q-bar class="bg-grey-1 flex items-center cursor-pointer"
                           clickable
                           @click="togglePassword = !togglePassword">
                        <q-icon :name="'mdi-chevron-'+(togglePassword?'down':'right')" size="sm"/>
                        <span>{{ $translate('auth.changepassword.title') }}</span>
                    </q-bar>

                    <template v-if="togglePassword">
                        <div class="q-mx-md flex column">
                            <q-input v-model="changePasswordForm.current_password"
                                     type="password"
                                     :label="$translate('users.profile.password.current')"
                                     stack-label aria-autocomplete="none" autocomplete="off"
                                     :error-message="changePasswordForm.errors.current_password"
                                     :error="changePasswordForm.errors.hasOwnProperty('current_password')"
                            />

                            <q-input v-model="changePasswordForm.password"
                                     type="password"
                                     :label="$translate('users.profile.password.new1')"
                                     stack-label aria-autocomplete="none" autocomplete="off"
                                     :error-message="changePasswordForm.errors.password"
                                     :error="changePasswordForm.errors.hasOwnProperty('password')"
                            />

                            <q-input v-model="changePasswordForm.password_confirmation"
                                     type="password"
                                     :label="$translate('users.profile.password.new2')"
                                     stack-label aria-autocomplete="none" autocomplete="off"
                                     :error-message="changePasswordForm.errors.password_confirmation"
                                     :error="changePasswordForm.errors.hasOwnProperty('password_confirmation')"
                            />

                            <q-btn icon="o_save"
                                   :label="$translate('buttons.changepassword')"
                                   color="positive"
                                   @click="changePassword"
                                   class="q-mx-auto"
                                   no-wrap no-caps dense
                            />
                        </div>
                    </template>
                </section>
                <section v-if="TWO_FACTOR_ENABLED">
                    <Profile2FA :user="user"/>
                </section>
                <q-dialog v-model="syncDataDialog">
                    <Card :title="$translate('users.profile.syncexternal.title')"
                          show-close-button header-toolbar>

                        <q-input v-model="syncDataForm.extlogin"
                                 :label="$translate('users.profile.syncexternal.login')"
                                 :prefix="ldap+'/'"
                                 :error="syncDataForm.errors.hasOwnProperty('extlogin')"
                                 :error-message="_.get(syncDataForm.errors,'extlogin')">
                            <template v-slot:append>
                                <q-icon name="o_person"/>
                            </template>
                        </q-input>

                        <q-input v-model="syncDataForm.extpassword" type="password"
                                 :label="$translate('users.profile.syncexternal.password')"
                                 :error="syncDataForm.errors.hasOwnProperty('extpassword')"
                                 :error-message="_.get(syncDataForm.errors,'extpassword')">
                            <template v-slot:append>
                                <q-icon name="o_password"/>
                            </template>
                        </q-input>

                        <template v-slot:actions>
                            <q-btn icon="upload"
                                   :label="$translate('buttons.syncexternal')"
                                   @click="submitSyncDataForm"
                                   color="positive"/>
                        </template>
                    </Card>
                </q-dialog>
                <q-dialog v-model="assignDataDialog">
                    <Card :title="$translate('users.profile.assignexternal.title')"
                          show-close-button header-toolbar>

                        <q-input v-model="syncDataForm.extlogin"
                                 :label="$translate('users.profile.assignexternal.login')"
                                 :prefix="ldap+'/'"
                                 :error="syncDataForm.errors.hasOwnProperty('extlogin')"
                                 :error-message="_.get(syncDataForm.errors,'extlogin')">
                            <template v-slot:append>
                                <q-icon name="o_person"/>
                            </template>
                        </q-input>

                        <q-input v-model="syncDataForm.extpassword" type="password"
                                 :label="$translate('users.profile.assignexternal.password')"
                                 :error="syncDataForm.errors.hasOwnProperty('extpassword')"
                                 :error-message="_.get(syncDataForm.errors,'extpassword')">
                            <template v-slot:append>
                                <q-icon name="o_password"/>
                            </template>
                        </q-input>

                        <template v-slot:actions>
                            <q-btn icon="upload"
                                   :label="$translate('buttons.assign-external')"
                                   @click="submitAssignDataForm"
                                   color="positive"/>
                        </template>
                    </Card>
                </q-dialog>
            </div>
            <div class="col">
                <q-bar class="bg-grey-1">{{ $translate('users.manage.main-data.title-additional') }}</q-bar>
                <div class="q-mx-md">
                    <template v-for="(item) in  user.additional_all">
                        <q-field :label="item.description.name" stack-label>
                            <template v-slot:control>
                                <div class="items-center full-width">
                                    <span>{{ item.value }}</span>
                                </div>
                            </template>
                            <template v-slot:append
                                      v-if="item.source">
                                <q-btn :icon="item.display?'visibility':'visibility_off'"
                                       flat dense
                                       @click="toggleVisibility(item)"
                                >
                                    <q-tooltip class="text-body2">{{ $translate('показывать всем') }}</q-tooltip>
                                </q-btn>
                                <q-icon name="o_lock" size="xs">
                                    <q-tooltip class="text-body2">{{
                                            $translate('users.profile.hasextlogin')
                                        }}
                                    </q-tooltip>
                                </q-icon>
                            </template>
                            <template v-slot:append
                                      v-if="!item.source">
                                <q-btn :icon="item.display?'visibility':'visibility_off'"
                                       flat dense
                                       @click="toggleVisibility(item)"
                                       :title="$translate('показывать всем')"
                                />
                                <q-btn icon="o_edit" flat dense>
                                    <q-popup-edit v-model="item.value"
                                                  :ref="'additional'+item.id"
                                                  v-slot="scope"
                                                  @update:modelValue="(v) => patchAdditionalData(item.id,v)"
                                                  buttons
                                                  :label-set="$translate('buttons.save')"
                                                  :label-cancel="$translate('buttons.cancel')"
                                                  :title="item.description.name">
                                        <q-input v-model="scope.value"
                                                 input-class="text-center"
                                                 style="min-width: 320px"
                                        />
                                    </q-popup-edit>
                                </q-btn>
                                <q-btn icon="o_delete" flat dense
                                       @click="deleteAdditionalData(item)"/>
                            </template>
                        </q-field>
                    </template>
                    <div :class="{'text-left':$q.screen.gt.sm,'text-right':$q.screen.lt.sm}">
                        <q-btn icon="o_add"
                               :label="$translate('buttons.add')"
                               class="q-mt-sm"
                               dense no-caps
                               @click="additionalDialog = true"/>
                    </div>
                </div>
                <q-dialog v-model="additionalDialog" no-backdrop-dismiss>
                    <Card :title="$translate('users.profile.edit.title-additional')"
                          in-modal show-close-button>
                        <q-select v-model="formAdditional.slug"
                                  :label="$translate('тип данных')"
                                  :options="optionsAdditional"
                                  options-dense
                                  map-options
                                  emit-value
                                  :error="formAdditional.errors.hasOwnProperty('slug')"
                                  :error-message="_.get(formAdditional.errors,'slug')"/>
                        <q-input v-model="formAdditional.value"
                                 :label="$translate('значение')"
                                 :error="formAdditional.errors.hasOwnProperty('value')"
                                 :error-message="_.get(formAdditional.errors,'value')"/>
                        <q-checkbox v-model="formAdditional.display"
                                    :label="$translate('показывать всем')"
                                    size="xs"/>
                        <template v-slot:actions>
                            <q-btn icon="o_save"
                                   :label="$translate('buttons.add')"
                                   color="positive"
                                   @click="submitFormAdditional"
                            />
                        </template>
                    </Card>
                </q-dialog>
            </div>
        </div>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";
import {Alert} from "@/Components/Block/index.js";
import Profile2FA from "@/Components/User/Profile/Profile2FA.vue";
import ProfileLangSelector from "@/Components/User/Profile/ProfileLangSelector.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const currentLocale = computed(() => usePage().props.locale)

const $q = useQuasar()

const props = defineProps({
    user: {
        type: Object
    },
    userDataClassificator: {
        type: Object
    },
    localeList: {
        type: Object
    },
    TWO_FACTOR_ENABLED: {
        type: Boolean
    }
})

const form = useForm(props.user)

const patchData = (field, value) => {
    axios.patch(route('profile.patch'), {
        field: field,
        value: value
    }).finally(() => {
        router.reload()
    })
}
const patchAdditionalData = (field_id, value) => {
    axios.patch(route('profile.additional.update', field_id), {
        value: value
    }).finally(() => {
        router.reload()
    })
}
const deleteAdditionalData = (item) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: item.value}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('profile.additional.delete', item.id));
    })
}

const additionalDialog = ref(false)

const formAdditional = useForm({
    slug: null,
    value: null,
    display: false
})

const optionsAdditional = computed(() => {
    return _.map(props.userDataClassificator.data, (item) => ({value: item.slug, label: item.label}))
})

const submitFormAdditional = () => {
    formAdditional.post(route('profile.additional.store'), {
        onSuccess: () => {
            router.reload()
            additionalDialog.value = false
        }
    })
}

const toggleVisibility = (item) => {
    axios.patch(route('profile.additional.visibility', item.id), {
        value: !item.display
    }).finally(() => {
        router.reload()
    })
}

const avatarDialog = ref(false)
const syncDataDialog = ref(false)
const assignDataDialog = ref(false)
const openAvatarDialog = () => {
    avatarDialog.value = !avatarDialog.value
}
const openSyncDataDialog = () => {
    syncDataDialog.value = !syncDataDialog.value
}
const openAssignDataDialog = () => {
    assignDataDialog.value = !syncDataDialog.value
}
const avatarForm = useForm({
    avatar: null
})
const syncDataForm = useForm({
    source: 'ldap',
    extlogin: null,
    extpassword: null
})
const submitAvatarForm = () => {
    avatarForm.post(route('profile.avatar'), {
        onSuccess: () => {
            avatarForm.reset()
            avatarDialog.value = false;
            router.reload()

        }
    })
}
const submitSyncDataForm = () => {
    syncDataForm.post(route('profile.external.sync'), {
        onSuccess: () => {
            syncDataForm.reset()
            syncDataDialog.value = false;
            router.reload()

        }
    })
}
const submitAssignDataForm = () => {
    syncDataForm.post(route('profile.external.assign'), {
        onSuccess: () => {
            syncDataForm.reset()
            assignDataDialog.value = false;
            router.reload()

        }
    })
}

const togglePassword = ref(false)

const changePasswordForm = useForm({
    current_password: null,
    password: null,
    password_confirmation: null
})
const changePassword = () => {
    changePasswordForm.patch($route('profile.password.update'), {})
}

</script>