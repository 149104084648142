import _ from "lodash";
export class ConfigHandler {
    config;
    constructor(config) {
        this.config = _.cloneDeep(config);
    }
    has(path) {
        return _.has(this.config, path);
    }
    get(path, fallback = null) {
        return _.get(this.config, path, fallback);
    }
    static new(config) {
        return new this(config);
    }
}
