<template>
    <q-bar class="bg-grey-1 flex items-center cursor-pointer justify-start"
           clickable
           @click="toggle2FASwitch">
        <q-icon :name="'mdi-chevron-'+(toggle2FA?'down':'right')" size="sm"/>
        <span class="q-mr-auto">{{ $translate('users.2fa.title') }}</span>
        <q-icon name="mdi-two-factor-authentication"
                size="sm"
                :class="{'text-negative': !twoFactorEnabled,'text-warning': twoFactorEnabled && !twoFactorConfirmed,'text-positive': twoFactorConfirmed}"
                class="q-mr-none q-ml-auto"/>
    </q-bar>

    <template v-if="toggle2FA">
        <div class="q-my-sm">
            <q-btn v-if="!twoFactorEnabled"
                   :label="$translate('users.2fa.enable')"
                   color="positive"
                   @click="enable2fa"
                   class="full-width"
            />
            <Alert v-else-if="twoFactorConfirmed"
                   variant="success"
                   :message="$translate('users.2fa.enabled')"
            />
        </div>


        <div class="q-my-md"
             v-html="$translate('users.2fa.enable-help',{link: 'https://docs.researchims.ru/guide_user/user_profile/#2fa_settings'})"></div>

        <template v-if="twoFactorEnabled">
            <div v-if="!twoFactorConfirmed" class="q-mb-md">
                <Alert variant="danger">{{ $translate('users.2fa.not-confirmed') }}</Alert>
            </div>


            <template v-if="!twoFactorConfirmed">
                <div class="text-bold">{{ $translate('users.2fa.confirm-title') }}</div>

                <q-tabs dense no-caps align="left" v-model="tabs" :breakpoint="0">
                    <q-tab name="totp">{{ $translate('users.2fa.tabs.totp') }}</q-tab>
                    <q-tab name="telegram" v-if="TELEGRAM_ENABLED">{{ $translate('users.2fa.tabs.telegram') }}</q-tab>
                </q-tabs>

                <q-tab-panels v-model="tabs">
                    <q-tab-panel name="totp">
                        <div class="flex column q-gutter-y-md">

                            <div>{{ $translate('users.2fa.enable-description') }}</div>

                            <Alert v-if="TELEGRAM_ENABLED"
                                   variant="info" hide-icon
                                   :html-message="$translate('users.2fa.enable-description-telegram-also')"/>

                            <div class="q-gutter-y-sm">

                                <div>{{ $translate('users.2fa.confirm-step1') }}</div>

                                <div class="row q-gutter-x-md">
                                    <div v-html="qrcode2fa" class="col-auto"/>
                                    <div class="col-auto">
                                        {{ $translate('buttons.or') }}
                                    </div>
                                    <div class="col q-gutter-sm column items-md-start">
                                        <q-btn :label="$translate('users.2fa.confirm-step1-win')"
                                               @click="copyToBufer(link2fa)"
                                               no-caps
                                        />

                                        <q-btn :label="$translate('users.2fa.confirm-step1-link')"
                                               :href="link2fa" target="_blank"
                                               no-caps
                                        />
                                    </div>
                                </div>

                                <div>{{ $translate('users.2fa.confirm-step2') }}</div>

                                <q-input v-model="validate2faForm.code"
                                         class="code2fa"
                                         input-class="text-center"
                                         inputmode="numeric"
                                         pattern="[0-9]*"
                                         autocomplete="one-time-code"
                                         outlined
                                         :error-message="_.get(validate2faForm.errors,'confirmTwoFactorAuthentication.code')"
                                         :error="validate2faForm.errors.hasOwnProperty('confirmTwoFactorAuthentication')"
                                />
                                <q-btn
                                        :label="$translate('users.2fa.validate')"
                                        color="positive"
                                        @click="validate2fa"
                                />
                            </div>

                            <q-btn v-if="twoFactorEnabled && twoFactorConfirmed"
                                   :label="$translate('users.2fa.disable')"
                                   color="negative"
                                   @click="disable2fa"
                            />

                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="telegram">
                        <div class="flex column q-gutter-y-md">
                            <div>{{ $translate('users.2fa.enable-description-telegram') }}</div>
                            <Alert variant="info" hide-icon
                                   :message="$translate('users.2fa.enable-description-telegram-only')"/>

                            <template v-if="user.telegram_id">

                                <div class="q-gutter-y-sm">

                                    <div class="row q-gutter-x-md">
                                        <q-btn :label="$translate('users.2fa.confirm-step1-telegram')"
                                               icon-right="mdi-send-outline"
                                               @click="send2faCode"
                                               no-caps
                                        />
                                    </div>

                                    <div>{{ $translate('users.2fa.confirm-step2-telegram') }}</div>

                                    <q-input v-model="validate2faForm.code"
                                             class="code2fa"
                                             input-class="text-center"
                                             inputmode="numeric"
                                             pattern="[0-9]*"
                                             autocomplete="one-time-code"
                                             outlined
                                             :error-message="_.get(validate2faForm.errors,'confirmTwoFactorAuthentication.code')"
                                             :error="validate2faForm.errors.hasOwnProperty('confirmTwoFactorAuthentication')"
                                    />
                                    <q-btn
                                            :label="$translate('users.2fa.validate')"
                                            color="positive"
                                            @click="validate2fa"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <Alert variant="warning"
                                       :html-message="$translate('users.2fa.enable-description-telegram-disabled')"/>
                            </template>
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </template>
            <template v-if="twoFactorConfirmed">
                <div class="flex justify-between full-width">
                    <q-btn
                            dense no-caps
                            icon="mdi-two-factor-authentication"
                            :label="$translate('users.2fa.recovery-codes')"
                            @click="openRecoveryCodeDialog"
                    />

                    <q-btn color="negative"
                           v-if="!TWO_FACTOR_REQUIRED"
                           dense no-caps
                           icon="mdi-cancel"
                           :label="$translate('users.2fa.disable')"
                           @click="disable2fa"
                    />
                </div>
                <q-dialog v-model="recoveryCodeDialog">
                    <Card :title="$translate('users.2fa.recovery-codes')" hide-actions show-close-button>
                        <q-list separator dense>
                            <template v-for="(code) in qrcode2farecovery">
                                <q-item>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ code }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-list>
                    </Card>
                </q-dialog>
            </template>
        </template>
    </template>
</template>
<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import {useQuasar, copyToClipboard} from "quasar";
import {Card, Alert} from "@/Components/Block/";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const currentLocale = computed(() => usePage().props.locale)
const TELEGRAM_ENABLED = computed(() => usePage().props.telegram_enabled)
const TWO_FACTOR_REQUIRED = computed(() => usePage().props.TWO_FACTOR_REQUIRED)

const $q = useQuasar()

const props = defineProps({
    user: {
        type: Object
    },
})

const twoFactorEnabled = computed(() => props.user.two_factor_enabled)
const twoFactorConfirmed = computed(() => props.user.two_factor_confirmed)

const toggle2FA = ref(false)

const toggle2FASwitch = () => {
    if (toggle2FA.value === false) {
        if (!props.user.two_factor_confirmed) {
            loadqr()
        }
    }
    toggle2FA.value = !toggle2FA.value;
}

const enable2fa = () => {
    axios.post($route('two-factor.enable'))
            .then(() => {
                router.reload();
                loadqr();
            })
}
const disable2fa = () => {
    axios.delete($route('two-factor.disable'))
            .then(() => {
                router.reload();
            })
}

const qrcode2fa = ref(null)
const link2fa = ref(null)
const qrcode2farecovery = ref(null)
const loadqr = () => {
    axios.get('/user/two-factor-qr-code')
            .then((response) => {
                qrcode2fa.value = response.data.svg
                link2fa.value = response.data.url
            });
}

const validate2faForm = useForm({
    code: null
})
const validate2fa = () => {
    validate2faForm.post(route('two-factor.confirm'))
}

const recoveryCodeDialog = ref(false)

const openRecoveryCodeDialog = () => {
    axios.get('/user/two-factor-recovery-codes')
            .then((response) => {
                qrcode2farecovery.value = response.data
            });
    recoveryCodeDialog.value = true;
}

const tabs = ref('totp')

const copyToBufer = (code) => {
    copyToClipboard(code)
            .then(() => {
                $q.notify({
                    color: 'positive',
                    message: 'Код для приложения скопирован в буфер',
                    position: "bottom",
                    progress: true,
                })
            })
            .catch(() => {
                $q.notify({
                    color: 'negative',
                    message: 'Ошибка копирования',
                    position: "bottom",
                    progress: true,
                })
            })
}

const send2faCode = () => {
    let form = useForm({})
    form.post(route('profile.two-factor.telegram'), {
        onSuccess: () => {
        }
    })
}
</script>
