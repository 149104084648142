<template>
    <Card :title="$translate('module-auth.form.two-factor.title')"
          title-bg="grey-1"
          title-classes="text-uppercase text-center"
          header-toolbar
          no-shrink
          no-card-section>
        <template v-slot:headerActions>
            <q-btn icon="mdi-help-circle-outline" dense flat>
                <q-menu auto-close anchor="center left" self="center right">
                    <q-list separator>
                        <q-item clickable
                                @click="showRecoveryCode=true">
                            <q-item-section side>
                                <q-icon name="mdi-lock-clock"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('users.2fa.input-recovery-code') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item href="https://docs.researchims.ru/guide_user/user_profile/#2fa_settings"
                                target="_blank"
                                clickable>
                            <q-item-section side>
                                <q-icon name="o_help_center"/>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    {{ $translate('common.manual_menu') }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </template>
        <q-inner-loading :showing="loading">
            <q-spinner-gears size="50px" color="primary"/>
        </q-inner-loading>
        <form id="login" method="post" :action="$route('two-factor.login.store')" @submit.prevent="submit">
            <input type="hidden" name="_token" :value="token">
            <q-input v-model="form.recovery_code"
                     v-if="showRecoveryCode"
                     type="text"
                     id="recovery_code"
                     name="recovery_code"
                     label-color="blue-grey"
                     input-class="text-center text-h5"
                     class="q-ma-md"
                     inputmode="numeric"
                     pattern="[\w\d]{10}-[\w\d]{10}"
                     autocomplete="one-time-code"
                     bottom-slots>
                <template v-slot:prepend>
                    <q-icon name="mdi-lock-clock" color="blue-grey" size="md"/>
                </template>
                <template v-slot:hint>
                    <div class="text-center">
                        {{ $translate('users.2fa.input-recovery-code-hint') }}
                    </div>
                </template>
            </q-input>
            <q-input v-model="form.code"
                     v-else
                     type="text"
                     id="code"
                     name="code"
                     label-color="blue-grey"
                     input-class="text-center text-h5"
                     class="q-ma-md"
                     inputmode="numeric"
                     pattern="[0-9]*"
                     autocomplete="one-time-code"
                     bottom-slots>
                <template v-slot:prepend>
                    <q-icon name="mdi-two-factor-authentication" color="blue-grey" size="md"/>
                </template>
                <template v-slot:hint>
                    <div class="text-center">
                        {{ $translate('users.2fa.input-totp-code') }}
                    </div>
                </template>
            </q-input>
        </form>
        <template v-slot:actions>
            <div class="full-width">
                <div class="text-center q-gutter-x-md">
                    <q-btn color="positive"
                           type="submit"
                           form="login"
                           :label="$translate('buttons.confirm')"
                           icon-right="login"
                           :loading="form.processing">
                        <template v-slot:loading>
                            <q-spinner-facebook/>
                        </template>
                    </q-btn>

                    <q-btn color="negative"
                           @click="logout"
                           :label="$translate('menu.user.logout')"
                           icon-right="login">
                    </q-btn>
                </div>
            </div>
        </template>
    </Card>

</template>

<script setup>
import Guest from "@/Layout/Guest.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed, ref, getCurrentInstance} from "vue";
import {Card} from "@/Components/Block";
import {useQuasar} from "quasar"
import _ from "lodash";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const $q = useQuasar();
defineOptions({layout: Guest})

const options = computed(() => usePage().props.options)
const errors = computed(() => usePage().props.errors)
const token = computed(() => usePage().props.csrf_token)

const loading = ref(false)

const form = useForm({
    code: null,
    recovery_code: null,
})

const submit = () => {
    $q.loading.show();
    $q.loadingBar.start()
    form.post($route('two-factor.login.store'), {
        onSuccess: () => {
            $q.loading.hide();
            $q.loadingBar.stop()
        },
        onFinish: () => {
            $q.loading.hide();
            $q.loadingBar.stop()
        }
    })
}
const logoutForm = useForm({});

const showRecoveryCode = ref(false)

const logout = () => {
    logoutForm.post(route('logout'), {},
            {
                onSuccess:() => router.visit('/')
            });
}
</script>