<template>
    <q-bar class="bg-grey-1 flex items-center cursor-pointer justify-start"
           clickable
           @click="toggleLocalization = !toggleLocalization">
        <q-icon :name="'mdi-chevron-'+(toggleLocalization?'down':'right')" size="sm"/>
        <span class="q-mr-auto">{{ $translate('menu.locale.localization') }}</span>
        <img :src='"/img/locale/"+currentLocale+".png"'
             :alt="$translate('menu.locale.localization')"
             :title="$translate('menu.locale.localization')"
             class="q-mr-none q-ml-auto"/>
    </q-bar>
    <q-slide-transition>
        <template v-if="toggleLocalization">
            <div class="q-mx-md ">
                <q-field stack-label tag="div" borderless>
                    <template v-slot:control>
                        <div class="items-center full-width">
                            <template v-for="(name,locale) in localeList">
                                <q-radio v-model="userLocale.locale" :val="locale" size="xs">
                                    <div class="flex items-center">
                                        <img :src='"/img/locale/"+locale+".png"' :alt='name'/>
                                        <span class="q-ml-sm">{{ name }}</span>
                                    </div>
                                </q-radio>
                            </template>
                        </div>
                    </template>
                    <template v-slot:append>
                        <q-btn icon="o_save"
                               :label="$translate('buttons.save')"
                               color="positive"
                               dense no-caps
                               @click="submitLocale"/>
                    </template>
                </q-field>
            </div>
        </template>
    </q-slide-transition>
</template>
<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Card from "@/Components/Block/Card.vue";
import {useQuasar} from "quasar";
import {Alert} from "@/Components/Block/index.js";
import Profile2FA from "@/Components/User/Profile/Profile2FA.vue";
import ProfileLangSelector from "@/Components/User/Profile/ProfileLangSelector.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const currentLocale = computed(() => usePage().props.locale)

const $q = useQuasar()

const props = defineProps({
    user: {
        type: Object
    },
    localeList: {
        type: Object
    },
})


const userLocale = useForm({
    locale: usePage().props.locale
});

const submitLocale = () => {
    location.href = $route('switch-locale', {lang: userLocale.locale})
}

const toggleLocalization = ref(false)

</script>
