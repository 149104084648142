<template>
    <q-list>
        <template v-for="(item) in equipments">
            <q-item>
                <q-item-section side top>
                    <q-checkbox v-model="item.checked" size="xs"/>
                </q-item-section>
                <q-item-section @click="() => item.checked = !item.checked"
                                class="cursor-pointer">
                    <q-item-label>{{ item.name }}</q-item-label>
                    <q-item-label caption v-if="item.comment">{{ item.comment }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-item-label>{{ item.time }}</q-item-label>
                </q-item-section>
            </q-item>

            <q-item v-if="item.checked">
                <q-item-section class="q-ml-xl q-pl-sm border-left">
                    <q-item-label>
                        <q-item-label>
                            {{ $translate('report-template.form.label.equipment_depend') }}
                        </q-item-label>
                        <q-radio v-model="item.depend" :val="1" size="xs">
                            {{ $translate('report-template.form.label.equipment_depend_1') }}
                            <q-tooltip class="text-body2">
                                {{ $translate('report-template.form.label.equipment_depend_1_help') }}
                            </q-tooltip>
                        </q-radio>
                        <q-radio v-model="item.depend" :val="0" size="xs">
                            {{ $translate('report-template.form.label.equipment_depend_0') }}
                            <q-tooltip class="text-body2">
                                {{ $translate('report-template.form.label.equipment_depend_0_help') }}
                            </q-tooltip>
                        </q-radio>
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item v-if="item.checked && _.size(item.complex_parts)>0">
                <q-item-section class="q-ml-xl q-pl-sm border-left">
                    <q-item-label class="text-italic">
                        {{ $translate('report-template.form.label.complex') }}
                    </q-item-label>
                    <q-item-label class="text-small" v-for="(complex) in item.complex_parts">
                        &mdash; {{ complex.equipment.display_name }}]
                    </q-item-label>
                </q-item-section>
            </q-item>
            <q-item v-if="item.checked && _.size(item.consumables)>0 ">
                <q-item-section class="q-ml-xl q-pl-sm border-left">
                    <q-item-label class="text-italic">
                        {{ $translate('report-template.form.label.consumable') }}
                    </q-item-label>
                    <div class="text-small" v-for="(cons) in item.consumables">
                        <q-checkbox v-model="cons.checked" size="xs">
                            {{ cons.name }} [{{ cons.used }}]
                        </q-checkbox>
                    </div>
                </q-item-section>
            </q-item>
            <q-item v-if="item.checked && _.size(item.worktimes)>0 ">
                <q-item-section class="q-ml-xl q-pl-sm border-left">
                    <q-item-label v-if="_.size(item.worktimes)>0" class="text-small">
                        <q-item-label class="text-italic">
                            {{ $translate('report-template.form.label.worktime') }}
                        </q-item-label>
                        <div v-for="(work) in item.worktimes">
                            <q-checkbox v-model="work.checked" size="xs">
                                {{ work.comment }} [{{ work.time }}]
                            </q-checkbox>
                        </div>
                    </q-item-label>
                </q-item-section>
            </q-item>
<!--      RIMS-1615      <q-item v-if="item.checked && additional">-->
<!--                <Addon v-model="item.additional"/>-->
<!--            </q-item>-->
            <q-separator/>
        </template>
    </q-list>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import _ from "lodash";
import Addon from "@/Components/ResearchReportTemplate/TemplateList/Addon.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const equipments = defineModel({type: Object})
defineProps({
    additional: {type: Boolean, default: false}
})
</script>