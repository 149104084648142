<template>
    <Card :title="$translate('menu.user.issues')"
          header-toolbar
          title-bg="deep-orange-4"
          title-text="white"
          hide-actions>
        <AlertInfo v-if="0 === issues.length">{{ $translate('users.issues.no-data') }}</AlertInfo>
        <q-list v-else separator >
            <template v-for="(issue) in issues" :key="issue.id">
                <q-item :class="$q.screen.lt.sm?'column':null">
                    <q-item-section avatar>
                        <q-icon :color="issue.lastCommentClass" name="mdi-circle" class="cursor-help" size="xs">
                            <q-tooltip class="text-body2">{{ issue.lastCommentTooltip }}</q-tooltip>
                        </q-icon>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label v-if="issue.can.view"
                                      @click="router.visit($route('helpdesk.issues.show', {issue: issue.id}))"
                                      class="cursor-pointer"
                        >
                            {{ issue.number }}
                        </q-item-label>
                        <q-item-label v-else>{{ issue.number }}</q-item-label>
                        <q-item-label>{{ issue.title }}</q-item-label>
                        <q-item-label v-html="issue.tags.join(' ')"></q-item-label>
                        <q-item-label>
                            <q-slide-transition>
                                <div v-show="issue.expanded">
                                    <q-card-section class="q-py-md" v-html="issue.description"/>
                                    <q-card-section class="text-right text-italic">
                                                <span>
                                                    {{
                                                        $translate('users.issues.discussion-participants')
                                                    }}: {{ issue.authorEmail }}
                                                </span>
                                    </q-card-section>
                                </div>
                            </q-slide-transition>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side top>
                        <q-item-label>
                            <q-chip outline size="sm" class="no-pointer-events">{{ issue.stateName }}</q-chip>
                        </q-item-label>
                        <q-item-label>
                                    <span class="cursor-help">
                                        {{ issue.commentsCount }} <q-icon name="far fa-comments" color="grey"/>
                                        <q-tooltip>{{ $translate('users.issues.comments') }}</q-tooltip>
                                    </span>
                            <span class="cursor-help q-ml-sm">
                                        {{ issue.attachmentsCount }} <q-icon name="fas fa-paperclip" color="grey"/>
                                        <q-tooltip>{{ $translate('users.issues.attachments') }}</q-tooltip>
                                    </span>
                        </q-item-label>
                        <div class="q-gutter-xs q-py-sm">
                            <q-btn :label="$translate(issue.expanded ? 'buttons.collapse' : 'buttons.expand')"
                                   :icon="issue.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                                   @click="issue.expanded = !issue.expanded"
                                   size="sm"
                            />
                            <q-btn v-if="issue.can.view"
                                   :label="$translate('buttons.detail')"
                                   @click="router.visit($route('helpdesk.issues.show', {issue: issue.id}))"
                                   size="sm"
                            />
                        </div>
                    </q-item-section>
                </q-item>
            </template>
        </q-list>
    </Card>
</template>

<script setup>
import {router} from "@inertiajs/vue3";
import {getCurrentInstance} from "vue";
import Card from "@/Components/Block/Card.vue";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import {useQuasar} from "quasar";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;

const $q = useQuasar()

const props = defineProps({
    issues: {
        type: Array,
        default: null
    }
});
</script>