<template>
    <UserLayout :title="$translate('users.title')">

        <DataTable :columns="columns"
                   :requestRoute="$route('ajax.users',{m:'list'})"
                   :onSuccess="onSuccess"
                   :search="filter"
                   allow-search
                   search-placeholder="поиск пользователя">
            <template v-slot:body-cell-last_name="props">
                <q-td :props="props" class="cursor-pointer"
                      v-if="props.row.actions.hasOwnProperty('detail')"
                      @click="router.visit($route('admin.users.show',props.row))">
                    {{ props.row.fulled_name }}
                </q-td>
                <q-td :props="props" class="cursor-pointer"
                      v-else>
                    {{ props.row.fulled_name }}
                </q-td>
            </template>
            <template v-slot:body-cell-checked="props">
                <q-td :props="props">
                    <q-icon size="sm" name="power_settings_new" :color="props.row.checked?'positive':'negative'"/>
                </q-td>
            </template>
            <template v-slot:body-cell-avatar="props">
                <q-td :props="props" auto-width>
                    <UserAvatar :avatar="props.row.avatar" size="2.5rem"></UserAvatar>
                </q-td>
            </template>
            <template v-slot:header-cell-two-factor="props">
                <q-th :props="props">
                    <q-icon name="mdi-two-factor-authentication"
                            size="sm"
                            class="cursor-pointer"
                    >
                        <q-menu cover anchor="top middle" auto-close>
                            <q-list>
                                <q-item dense>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ $translate('users.2fa.title') }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-btn-group spread>
                                    <q-btn icon="mdi-two-factor-authentication"
                                           color="warning"
                                           :label="$translate('включена')"
                                           @click="filterBy('two-factor','enabled')"
                                           flat no-caps no-wrap/>
                                    <q-btn icon="mdi-two-factor-authentication"
                                           color="positive"
                                           :label="$translate('подтверждена') "
                                           @click="filterBy('two-factor','confirmed')"
                                           flat no-caps no-wrap/>
                                </q-btn-group>
                            </q-list>
                        </q-menu>
                    </q-icon>
                </q-th>
            </template>
            <template v-slot:body-cell-two-factor="props">
                <q-td :props="props">
                    <q-icon v-if="_.get(props.row,'2fa') != 'disabled'"
                            name="mdi-two-factor-authentication"
                            size="sm"
                            :color="_.get(props.row,'2fa')=='confirmed'?'positive':'warning'"
                    >
                    </q-icon>
                </q-td>
            </template>
            <template v-slot:body-cell-roles="props">
                <q-td :props="props" wrap>
                    <template v-for="(role) in props.row.roles">
                        <q-chip square dense outline color="grey-8">
                            <div>{{ role.name }}</div>
                            <q-btn v-if="_.has(role,'children')"
                                   flat dense icon="o_more" size="sm" text-color="indigo-2">
                                <q-tooltip size="lg">{{ role.children.join("; ") }}</q-tooltip>
                            </q-btn>
                            <q-btn v-else flat dense icon="o_cancel" size="sm" text-color="red-3"
                                   @click="deleteRole(props.row.id, role)"
                                   :title="$translate('users.actions.delete_role')"/>
                        </q-chip>
                    </template>
                </q-td>
            </template>
            <template v-slot:body-cell-actions="props">
                <q-td :props="props" class="no-wrap">
                    <DataTableActions :actions="props.row.actions"
                                      :name="props.row.fulled_name"
                                      @on-success="onSuccess">
                        <template v-slot:default v-if="_.has(props.row.actions,'edit')">
                            <q-item clickable @click="openRoleDialog(props.row)">
                                <q-item-section>
                                    <q-item-label>
                                        {{ $translate('users.actions.add_role') }}
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section avatar>
                                    <q-icon name="o_add_moderator" size="xs"/>
                                </q-item-section>
                            </q-item>
                        </template>
                    </DataTableActions>
                </q-td>
            </template>
        </DataTable>
    </UserLayout>
    <q-dialog no-backdrop-dismiss v-model="addRoleDialog">
        <AddRoleForm :user="selectedUser" @on-success="onSuccess"/>
    </q-dialog>
</template>

<script setup>

import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";

import _ from "lodash";
import {useQuasar} from "quasar";
import DataTable from "@/Components/DataTable.vue";
import DataTableActions from "@/Components/DataTableActions.vue";
import AddRoleForm from "@/Components/User/AddRoleForm.vue";
import UserLayout from "@/Pages/Admin/Users/UserLayout.vue";
import UserAvatar from "@/Components/UserAvatar.vue";

const app = getCurrentInstance()
const $q = useQuasar();

const $translate = app.appContext.config.globalProperties.$translate
const $route = app.appContext.config.globalProperties.$route

const tableRef = ref()
const rows = ref([])
const filter = ref('')
const loading = ref(false)

const addRoleDialog = ref(false)

const selectedUser = ref(null)

defineProps({
    can: {
        type: Object,
        default: {
            create: false,
            export: false,
            import: false,
            merge: false
        }
    }
})

const openRoleDialog = (user_id) => {
    selectedUser.value = user_id
    addRoleDialog.value = true
}
const deleteRole = (user_id, role) => {
    $q.dialog({
        title: $translate('delete-dialog.title'),
        message: $translate('delete-dialog.message', {name: role.name}),
        cancel: {
            color: 'negative',
            noCaps: true,
            label: $translate('delete-dialog.action.cancel')
        },
        ok: {
            color: 'positive',
            noCaps: true,
            label: $translate('delete-dialog.action.confirm')
        },
        persistent: true
    }).onOk(() => {
        router.delete(route('admin.users.delete-role', [user_id, role.id]), {
            onSuccess: () => onSuccess()
        })
    })
}

const columns = [
    {
        name: 'actions', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'checked', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'avatar', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'last_name', label: $translate('users.fields.fulled_name'),
        align: 'left', sortable: true,
    },
    {
        name: 'email', label: $translate('users.fields.email'),
        sortable: true, align: 'left',
        field: row => row.email,
    },
    {
        name: 'ext_login', label: $translate('users.fields.extlogin'),
        sortable: true, align: 'left',
        field: row => row.ext_login,
    },
    {
        name: 'two-factor', align: 'center', label: '',
        style: "width: 2rem",
    },
    {
        name: 'roles', label: $translate('users.fields.roles'),
        align: 'left',
    },
]

const onSuccess = () => {
    addRoleDialog.value = false
}

const filterBy = (field, value) => {
    filter.value = '' + field + ':"' + value + '"';
    onSuccess()
}

onMounted(() => {
    onSuccess()
});
</script>
