<template>
    <q-list>
        <template v-for="(item) in worktimes">
            <q-item>
                <q-item-section side>
                    <q-checkbox v-model="item.checked" size="xs"/>
                </q-item-section>
                <q-item-section @click="item.checked=!item.checked" class="cursor-pointer">
                    <q-item-label>{{ item.comment }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-item-label>{{ item.time }}</q-item-label>
                </q-item-section>
            </q-item>
<!--      RIMS-1615      <q-item v-if="item.checked && additional">-->
<!--                <Addon v-model="item.additional"/>-->
<!--            </q-item>-->
            <q-separator/>
        </template>
    </q-list>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {router} from "@inertiajs/vue3";
import _ from "lodash";
import axios from "axios";
import Addon from "@/Components/ResearchReportTemplate/TemplateList/Addon.vue";

const app = getCurrentInstance();
const $translate = app.appContext.config.globalProperties.$translate;
const $route = app.appContext.config.globalProperties.$route;
const worktimes = defineModel({type: Object})
defineProps({
    additional: {type: Boolean, default: false}
})
</script>