<template>
    <Card :title="moduleName" header-toolbar>
        <HasChanges v-if="settingsForm.isDirty"/>
        <div class="row q-gutter-x-md">
            <div class="col">
                <Card :title="$translate('ssc.settings.project.title')" no-card-section hide-actions>
                    <AlertInfo>{{ $translate('ssc.settings.project.description') }}</AlertInfo>
                    <q-card-section v-if="department.project_reglament_root && department.parent_id>0">
                        <q-field :label="$translate('module-rims.settings.RIMS_PROJECT_ROOT_INFO')"
                                 stack-label label-color="black"
                                 :hint="$translate('module-rims.settings.RIMS_PROJECT_ROOT_INFO_description')">
                            <template v-slot:control>
                                {{ department.project_reglament_root }}
                            </template>
                        </q-field>
                    </q-card-section>
                    <q-card-section>
                        <q-input type="textarea" rows="3"
                                 v-model="settingsForm['RIMS_PROJECT_REGLAMENT_SSC_' + department.id + '']"
                                 :label="$translate('module-rims.settings.RIMS_PROJECT_INFO')" stack-label
                                 label-color="black"
                                 :hint="$translate('module-rims.settings.RIMS_PROJECT_INFO_description')">
                        </q-input>
                    </q-card-section>
                    <q-separator/>
                    <q-card-section>
                        <div class="q-mt-md" v-if="department.parent_id>0">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_PROJECT_REGLAMENT_ROOT_SSC_' + department.id + '_PRINT']"
                                        :label="$translate('module-rims.settings.RIMS_PROJECT_ROOT_INFO_PRINT')"
                            />
                        </div>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_PROJECT_REGLAMENT_SSC_' + department.id + '_PRINT']"
                                        :label="$translate('module-rims.settings.RIMS_PROJECT_INFO_PRINT')"
                            />
                        </div>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_PROJECT_REGLAMENT_SSC_' + department.id + '_AGREE']"
                                        :label="$translate('module-rims.settings.RIMS_PROJECT_AGREE_PRINT')"
                            />
                        </div>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_SHOW_WORK_DESCRIPTION_SSC_' + department.id + '']"
                                        :label="$translate('module-rims.settings.RIMS_PROJECT_WORK_REQUIRED')"
                            />
                        </div>
                    </q-card-section>

                    <q-card-section v-if="_.get(department,'can.settings.project-cost')">
                        <q-item-label>
                            {{ $translate('module-rims.settings.PROJECT_COST_VIEW_SSC') }}
                        </q-item-label>
                        <div class="full-width">
                            <q-option-group v-model="settingsForm['PROJECT_COST_VIEW_SSC_' + department.id + '']"
                                            size="sm"
                                            :options="PROJECT_COST_VIEW_SSC_OPTIONS"/>
                        </div>
                        <q-item-label caption>
                            {{ $translate('module-rims.settings.PROJECT_COST_VIEW_SSC_description') }}
                        </q-item-label>
                    </q-card-section>
                </Card>
            </div>
            <div class="col">
                <Card :title="$translate('ssc.settings.research.title')" no-card-section hide-actions>
                    <AlertInfo>{{ $translate('ssc.settings.research.description') }}</AlertInfo>
                    <q-card-section>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_SSC_' + department.id + '']"
                                        :label="$translate('module-rims.settings.RIMS_RESEARCH_REQUIRED_REPORT_LIMIT')"
                            />
                        </div>
                        <div class="q-mt-md"
                             v-if="settingsForm['RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_SSC_' + department.id + ''] === '1'">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_EMP_SSC_' + department.id + '']"
                                        :label="$translate('module-rims.settings.RIMS_RESEARCH_REQUIRED_REPORT_LIMIT_EMP')"
                            />
                        </div>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_PROJECT_CREATE_REQUEST_LOCK_SSC_' + department.id + '']"
                                        :label="$translate('module-rims.settings.RIMS_RESEARCH_CREATE_LOCK_EMP')"
                            />
                        </div>
                        <div class="q-mt-md">
                            <q-checkbox size="sm" dense true-value="1" false-value="0"
                                        v-model="settingsForm['RIMS_ALLOW_ONE_TIME_ASSAY_SSC_' + department.id + '']"
                                        :label="$translate('module-rims.settings.RIMS_RESEARCH_ONE_TIME_ASSAY')"
                            >
                                <q-tooltip>
                                    {{ $translate('module-rims.settings.RIMS_RESEARCH_ONE_TIME_ASSAY_description') }}
                                </q-tooltip>
                            </q-checkbox>
                        </div>
                    </q-card-section>
                </Card>
            </div>
        </div>
        <HasChanges v-if="settingsForm.isDirty"/>
        <template v-slot:actions>
            <q-btn color="positive" icon="save" :label="$translate('buttons.save')" @click="settingsFormSubmit"/>
        </template>
    </Card>
</template>

<script setup>
import {computed, getCurrentInstance, onBeforeMount} from "vue";
import _ from "lodash";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Card from "@/Components/Block/Card.vue";
import AlertInfo from "@/Components/Block/AlertInfo.vue";
import HasChanges from "@/Components/Block/HasChanges.vue";

const app = getCurrentInstance()
const $translate = app.appContext.config.globalProperties.$translate
const props = defineProps({
    department: {},
})
const moduleName = computed(() => _.get(usePage().props.services, 'rims.name'));

const PROJECT_COST_VIEW_SSC_OPTIONS = computed(() => {
    return _.map($translate('module-rims.settings.PROJECT_COST_VIEW_SSC_values'), (v, k) => ({value: k, label: v}))
})

let settingsForm = null;

onBeforeMount(() => {
    let form = {}
    let settings = usePage().props.settings

    _.set(form, "RIMS_PROJECT_REGLAMENT_ROOT_SSC_" + props.department.id + "_PRINT",
            _.get(settings, "RIMS_PROJECT_REGLAMENT_ROOT_SSC_" + props.department.id + "_PRINT", '0'));
    _.set(form, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "", null))
    _.set(form, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "_PRINT",
            _.get(settings, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "_PRINT", '0'))
    _.set(form, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "_AGREE",
            _.get(settings, "RIMS_PROJECT_REGLAMENT_SSC_" + props.department.id + "_AGREE", '0'))
    _.set(form, "RIMS_SHOW_WORK_DESCRIPTION_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_SHOW_WORK_DESCRIPTION_SSC_" + props.department.id + "", '0'))
    _.set(form, "RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_SSC_" + props.department.id + "", '0'))
    _.set(form, "RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_EMP_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_REQUIRE_RESEARCH_REPORT_LIMIT_EMP_SSC_" + props.department.id + "", '0'))
    _.set(form, "RIMS_PROJECT_CREATE_REQUEST_LOCK_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_PROJECT_CREATE_REQUEST_LOCK_SSC_" + props.department.id + "", '0'))
    _.set(form, "PROJECT_COST_VIEW_SSC_" + props.department.id + "",
            _.get(settings, "PROJECT_COST_VIEW_SSC_" + props.department.id + "", 'full'))
    _.set(form, "RIMS_ALLOW_ONE_TIME_ASSAY_SSC_" + props.department.id + "",
            _.get(settings, "RIMS_ALLOW_ONE_TIME_ASSAY_SSC_" + props.department.id + "", '0'))
    settingsForm = useForm(form);
});


const settingsFormSubmit = () => {
    settingsForm.post(route('ssc.settings.update', props.department), {
        onSuccess: () => {
            router.reload()
        }
    })
}
</script>